import { render, staticRenderFns } from "./parts.vue?vue&type=template&id=43865ca2&scoped=true&"
import script from "./parts.vue?vue&type=script&lang=js&"
export * from "./parts.vue?vue&type=script&lang=js&"
import style0 from "./parts.vue?vue&type=style&index=0&id=43865ca2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43865ca2",
  null
  
)

export default component.exports