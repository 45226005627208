<template>
    <div class="warper">
        <div class="warper-tit">

            <van-icon name="close" @click="$emit('closePopup')" />
        </div>
        <p class="title">毁形照片</p>

        <div v-if="!isWechat">
            <van-uploader v-model="fileList1" :after-read="uploadershang" :before-delete="deleteimg" preview-size="100%" :max-count="1">
                <img style="width:100%" src="../../../assets/zhanwu.jpg" alt="">
            </van-uploader>
        </div>
        <div v-else>
            <img v-if="!imageUrl" style="width:100%" src="../../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'hui')">
            <div v-else style="width:100%">
                <van-image class="vanImage" style="width:100%"  :src="imageUrl" @click="bigImg(imageUrl)" >
                    <van-icon class="iconX" name="close" @click.stop="delImgHan('hui')" />
                </van-image>
            </div>
        </div>

        <div>
       
             <van-button class="van-button-p"  type="info"  @click="tijiao" size="large">提交</van-button>
        </div>
    </div>
</template>

<script>
import { destructPhotoTask,recordsFind } from '@/api/gong'
import {Toast} from 'vant'
    export default {
        data() {
            return {
                fileList1: [],
                imageUrl:'',
                voice:'',
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                islable:"",//判断上川的什么类型照片进行识别
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: {
            async find(id,type){
                this.fileList1 = []
                const {data} = await recordsFind({id,type}).then(res=>res)
                this.imageUrl = data.data.val
                if(data.data.val){
                    this.fileList1.push({url:data.data.val})
                }else{
                    this.fileList1 = []
                }
                
                console.log(this.fileList1)
            },
            async tijiao(){
                try{
                    const {data} = await destructPhotoTask({id:this.$route.query.id,image:this.imageUrl}).then(res=>res)
                    this.$toast.success(data.msg)
                    this.$emit('closePopup')
                }
                catch{

                }
              
            },
              //上传照片
           uploadershang(file){
                file.status = "uploading";
                file.message = "上传中...";
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
                this.$http.post("/index.php/index/upload/index", params)
                .then((res) => {
                if (res.data.code == 0) {
                    let url = 'index.php/vehicles_upload/idcardBusinessLicense'
                    this.imageUrl = res.data.data
             
                    file.status = "done";
                } else if(res.data.code == -1){
                   this.islogout()
                }else{
                    file.status = "failed";
                    file.message = "上传失败";
                    this.imageUrl=''
                    this.fileList1=[]//行驶证正面照片
                }
                }).catch(()=>{
                    this.imageUrl = ''
                     this.fileList1=[],//行驶证正面照片
                    file.status = "failed";
                    file.message = "上传失败";
                    this.$toast.fail('网络开小差了，请稍后重试！')
                })
           },
               //删除
            deleteimg(i){
                // console.log(i,arguments)
                this.fileList1 = []
                this.imageUrl = []
                return true
            },
        },
    }
</script>

<style lang="scss" scoped>

.warper-tit{
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;
    .van-icon{
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
    .title{
        font-weight: bold;
        font-size: 22px;
        padding: 10px;
    }
    .upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
.upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>