<template>
    <div>

        <Head :title="'工序回用件录入'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <div class="content" :style="{ paddingTop: '10px' }">
            <van-form class="forms" v-for="(item, index) in infoObj" :key="index"
                :style="{ border: '1px solid orange', borderRadius: '10px', margin: '15px' }">
                <van-field readonly clickable v-model="CarTypePickerCtrl[index].carTypeName" name="picker"
                    :label="'配件分类：'" :placeholder="'点击选择配件分类'" @click="showTypePicker(index)" />
                <van-popup v-model="CarTypePickerCtrl[index].isShow" position="bottom" :close-on-click-overlay="false">
                    <van-picker 
                    show-toolbar 
                    :columns="columns" 
                    :default-index="CarTypePickerCtrl[index].defaultIndex"
                    value-key="name" 
                    @confirm="onConfirm(index, $event)" 
                    @cancel="closeTypePicker(index)" />
                </van-popup>
                <van-field v-model="item.patrsName" label="配件名称" placeholder="请输入配件名称" />
                <van-field v-model="item.num" label="配件数量" placeholder="请输入配件数量" />
                <van-field v-model="item.weight" label="配件重量" placeholder="请输入配件重量" />
                <van-field v-model="item.color" label="配件颜色" placeholder="请输入配件颜色" />

                <van-field name="radio" label="是否属于五大总成">
                    <template #input>
                        <van-radio-group v-model="item.five_big" direction="horizontal">
                            <van-radio :name="1">属于</van-radio>
                            <van-radio :name="2">不属于</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <div v-if="!isWechat">
                    <van-field name="uploader" label="配件图片">
                        <template #input>
                            <van-uploader v-model="item.imgs" :after-read="uploadershang(index, 'restsImg')"
                                :before-read="beforeRead" multiple :before-delete="deleteimg2"></van-uploader>
                        </template>

                    </van-field>
                </div>

                <div class="upimg" v-else>
                    <p>配件图片</p>
                    <p>
                        <span class="imgbox" v-for="(item, index) in item.img_boxs" :key="index">
                            <van-image class="vanImage" width="80" height="80" :src="item" @click="bigImg(item)">
                                <van-icon class="iconX" name="close" @click="delImgHan('huiyongPhoto', index)" />
                            </van-image>
                        </span>
                        <span class="uploaderImg" @click="WeChooseImage(9, 'huiyongPhoto', index)">
                            <van-icon class="iconz" name="plus" />
                        </span>
                    </p>
                </div>
                <van-button class="van-button-p" type="warning" @click="del(index)" size="large">删除当前配件</van-button>
            </van-form>

            <hr>
            <van-form :style="{ margin: '30px' }">

                <van-button class="van-button-p" type="info" @click="zengjiaData" size="large">增加配件</van-button>
                <van-button class="van-button-p" type="danger" @click="defaultPart" size="large"
                    :style="{ marginTop: '15px' }">载入标准拆车件</van-button>
            </van-form>


            <van-form class="isSubmit" :style="{ margin: '30px' }">

                <van-button class="van-button-p" type="info" @click="Submit(0)">保存</van-button>
                <van-button class="van-button-p" type="info" @click="Submit(1)">提交</van-button>
            </van-form>
        </div>


        <van-popup v-model="isAddShow" :closeable="false" :close-on-click-overlay="false" position="bottom" :style="{
            height: '50%',
        }">
            <defaultDismantleParts ref="defaultDismantlePartsRef" @closePopup="closeDefaultPartPopup"
                @addParts="addParts" />
        </van-popup>
    </div>
</template>

<script>
import Head from '@/components/Head'
import defaultDismantleParts from '@/components/defaultDismantle/parts.vue'
import { reusablePartsTask, recordsFind } from '@/api/gong'
import { Toast, Dialog } from 'vant'
export default {
    components: {
        Head, defaultDismantleParts
    },
    data() {
        return {
            isAddShow: false,
            imageFile: [],
            WXimg: [],
            username: "",
            password: '',
            toast: null,
            wximagelocalids: [],
            columns: [],
            CarTypePickerCtrl: [],
            islable: "",//判断上川的什么类型照片进行识别
            idsIndex: null,
            infoObj: [
                // {
                //     patrsName:"",//配件名称
                //     num:"",//配件数量
                //     color:"",//配件颜色
                //     carAccessoriesId:"",//配件分类
                //     five_big:"",//五大总成
                //     weight:"",//重量
                //     img_boxs:[],//照片
                //     imgs:[],
                // },
            ]
        }
    },
    watch: {
        wximagelocalids: {
            handler(newValue, oldValue) {
                if (newValue.length > 0) {
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                } else {
                    this.toast.clear();
                }
            },
            deep: true,
        }
    },
    methods: {
        showTypePicker(index) {
            this.CarTypePickerCtrl.forEach((item, idx) => {
                this.CarTypePickerCtrl[idx].isShow = false
            })
            this.CarTypePickerCtrl[index].isShow = true
            console.log(index, "开启")
            console.log(this.CarTypePickerCtrl)
        },
        closeTypePicker(index) {
            this.CarTypePickerCtrl[index].isShow = false
            console.log(index, "关闭")
            console.log(this.CarTypePickerCtrl)
        },
        addParts(parts) {
            let that = this
            console.log("载入配件列表", parts)
            this.closeDefaultPartPopup();
            this.CarTypePickerCtrl = [];
            this.infoObj = [];
            parts.forEach((item) => {
                that.zengjiaData(item);
            })
        },
        //新增列表
        zengjiaData(data = {}) {
            let that = this
            let dataBasic = {
                name: "",
                carPartsType: "",
                carAccessoriesId: "",//配件分类ID
                five_big: "",
                weight: "",
                account: "",
            }
            data = Object.assign(dataBasic, data)

            let obj = {
                patrsName: data.name,//配件名称
                num: data.account,//配件数量
                color: "",//配件颜色
                carAccessoriesId: data.caraccessories_id,//配件分类ID
                five_big: data.five_big,//五大总成
                weight: data.weight,//重量
                img_boxs: [],//照片
            }
            this.showData(obj)
        },
        showData(obj) {
            console.log("obj", obj)


            obj.imgs = []
            if (obj.img_boxs.length > 0) {
                obj.img_boxs.forEach(k => {
                    obj.imgs.push({ url: k })
                })
            }

            let CarTypePickerCtrl = {}
            CarTypePickerCtrl.isShow = false

            let arr = this.columns.filter(function (data) {
                return data.id === obj.carAccessoriesId;
            });

            if (typeof (arr[0]) != 'undefined') {
                CarTypePickerCtrl.defaultIndex = this.columns.indexOf(arr[0])
            } else {
                CarTypePickerCtrl.defaultIndex = -1
            }

            console.log(CarTypePickerCtrl.defaultIndex)
            if (CarTypePickerCtrl.defaultIndex > -1) {
                CarTypePickerCtrl.carTypeName = this.columns[CarTypePickerCtrl.defaultIndex].name
            }

            console.log(CarTypePickerCtrl.carTypeName)
            this.CarTypePickerCtrl.push(CarTypePickerCtrl)
            this.infoObj.push(obj)
            console.log(this.CarTypePickerCtrl)
        },
        closeDefaultPartPopup() {
            this.isAddShow = false
        },
        defaultPart() {
            this.isAddShow = true
        },
        onConfirm(index, e) {
            this.infoObj[index].carAccessoriesId = e.id
            this.CarTypePickerCtrl[index].carTypeName = e.name
            this.closeTypePicker(index)
        },
        kong(id, type) {
            this.infoObj = [
                // {
                //     patrsName:"",//配件名称
                //     num:"",//配件数量
                //     color:"",//配件颜色
                //     carAccessoriesId:"",//配件分类
                //     five_big:"",//五大总成
                //     weight:"",//重量
                //     img_boxs:[],//照片
                //     imgs:[],
                // },
            ]
            this.$http.post("/index.php/index/Simply", { type: "carAccessories" }).then((res) => {
                this.columns = res.data.data;
                this.find(id, type)
            });
        },
        async find(id, type) {
            let that = this
            const { data } = await recordsFind({ id, type }).then(res => res)
            if (data.data.val) {
                that.CarTypePickerCtrl = [];
                that.infoObj = [];
                data.data.val.forEach((item, index) => {
                    that.showData(item)
                })
            }
        },
        //删除
        del(i) {
            this.CarTypePickerCtrl.splice(i, 1)
            this.infoObj.splice(i, 1)
        },
        //确定
        Submit(num) {

            if (num == 0) {
                this.save(num)
            } else {
                Dialog.confirm({
                    title: '提示',
                    message: '提交之后不可修改！',
                })
                    .then(async () => {
                        this.save(num)
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
        async save(num) {
            let obj = {
                dismantledCarParts: this.infoObj,
                type: num,
                id: this.$route.query.id
            }
            const { data } = await reusablePartsTask(obj).then(res => res)
            this.$toast.success(data.msg)
            this.$emit('closePopup')
        },


        beforeRead(file) {
            if (file instanceof Array) {
                for (let i = 0; i < file.length; i++) {
                    if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
            } else {
                if ((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
                    Toast('请上传 jpg/png 格式图片');
                    return false;
                }
                return true;
            }
        },

        // 上传照片
        uploadershang(i, name) {
            return file => {
                file.status = "uploading";
                file.message = "上传中...";
                if (file instanceof Array) {
                    file.forEach(item => {
                        this.upImages(i, name, item)
                    })
                } else {
                    this.upImages(i, name, file)
                }
            }
        },
        upImages(i, name, file) {
            file.status = "uploading";
            file.message = "上传中...";
            let params = new FormData(); //创建form对象
            params.append("file", file.file);
            this.$http.post("/index.php/index/upload/index", params)
                .then((res) => {
                    // toast.clear()//清除加载效果
                    if (res.data.code == 0) {
                        file.status = "done";
                        console.log(file)
                        // this.infoObj[i].imgs.forEach(item=>{
                        //     if(item.file.name == file.file.name){
                        //         item.content = res.data.data
                        //     }
                        // })
                        file.content = res.data.data
                        this.infoObj[i].img_boxs.push(res.data.data)

                    } else if (res.data.code == -1) {
                        this.islogout()
                    } else {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.$toast.fail(res.data.msg)
                    }
                }).catch(() => {
                    if (name == 'restsImg') {
                        this.infoObj.img_boxs = []
                    }
                    // toast.clear()//清除加载效果
                    file.status = "failed";
                    file.message = "上传失败";
                    this.$toast.fail('网络开小差了，请稍后重试！')
                })
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    margin-top: 50px;

    .btn-bom {
        padding: 0 10px;
        margin-top: 50px;

        .content-btn {
            margin: 10px 0;
        }
    }
}

.warper-tit {
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;

    .van-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.title {
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
}

.forms {
    padding: 20px;
    position: relative;

    .van-icon {
        position: absolute;
        right: 10px;
        top: 0px;
    }
}

.isSubmit {
    margin-top: 1rem;
    text-align: center;

    .van-button-p {
        margin: 0 10px;
    }
}

.upimg {
    padding: 0 12px;

    .uploaderImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
        position: relative;

        .iconz {
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>