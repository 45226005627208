<template>
    <div>
        <Head :title="'工序大宗拆解物信息'" :isPopup="true" @closePopup="$emit('closePopup')" />
       
        <div class="content" :style="{ paddingTop: '10px' }">
            <div class="first" v-for="item in list_a" :key="item.dangerClassId">
                <van-field v-model="item.weight" :label="item.name" :placeholder="'请输入'+item.name+'重量'">
                    <template #button>
                        <span>Kg</span>
                    </template>
                </van-field>
            </div>
            <van-form :style="{ margin: '30px' }">

                <van-button class="van-button-p" type="danger" @click="defaultBulk" size="large"
                    :style="{ marginTop: '15px' }">载入标准大宗拆解物</van-button>
            </van-form>

            <van-form class="isSubmit" :style="{ margin: '30px' }">
                <van-button class="van-button-p"  type="info"  @click="Submit(0)" >保存</van-button>
                <van-button class="van-button-p"  type="info"  @click="Submit(1)">提交</van-button>
            </van-form>
        </div>
        
        <van-popup v-model="isAddShow" :closeable="false" :close-on-click-overlay="false" position="bottom" :style="{
            height: '50%',
        }">
            <defaultDismantleBulk ref="defaultDismantleBulkRef" @closePopup="closeDefaultBulkPopup" @addBulk="addBulk" />
        </van-popup>
    </div>
</template>

<script>
import Head from '@/components/Head'
import defaultDismantleBulk from '@/components/defaultDismantle/bulk.vue'
import {bulkTasks,recordsFind } from '@/api/gong'
import {Toast,Dialog} from 'vant'
    export default {
        components: {
            Head, defaultDismantleBulk
        },
        data() {
            return {
                list_a:[],
                isAddShow:false,
            }
        },
        methods: {
            addBulk(bulk){
                console.log(bulk)
                console.log(this.list_a)
                this.list_a.forEach(
                    (item,index)=>{
                        let arr = bulk.filter(function (data) {
                            return data.bulkdismantlingclass_id === item.bulkDismantlingClassId;
                        });
                        if (typeof (arr[0]) != 'undefined') {
                            item.weight=arr[0].weight
                        } 
                    }
                )
                this.closeDefaultBulkPopup()
            },
            closeDefaultBulkPopup() {
                this.isAddShow = false
            },
            defaultBulk() {
                this.isAddShow = true
            },
            async kong(id,type) {
                const {data} = await this.$http.post("/index.php/index/Simply", { type: "bulkDismantlingClass" }).then(res =>res)
                let arr = []
                let obj = {}
                this.find(id,type)
                data.data.forEach(element=>{
                    obj = {bulkDismantlingClassId:element.id,name:element.name,weight:''}
                    arr.push(obj)
                })
                this.list_a = arr
                
            },
            async Submit(num){
                // 
                    if(num == 0){
                    this.a(num)
                }else{
                      Dialog.confirm({
                        title: '提示',
                        message: '提交之后不可修改！',
                        })
                    .then(async() => {
                        this.a(num)
                    })
                    .catch(() => {
                        // on cancel
                    });
                } 
                
            },
            async a(num){
                try{
                    let obj = {
                        id:this.$route.query.id,
                        type:num,
                        bulkWarehousing:this.list_a
                    }
                    const {data} = await bulkTasks(obj).then(res=>res)
                    this.$toast.success(data.msg)
                    this.$emit('closePopup')
                }
                catch{}
            },
             async find(id,type){
                const {data} = await recordsFind({id,type}).then(res=>res)
                console.log(2)
                if(data.data.val){

                    this.list_a = data.data.val
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.content {
    margin-top: 50px;

    .btn-bom {
        padding: 0 10px;
        margin-top: 50px;

        .content-btn {
            margin: 10px 0;
        }
    }
}
.warper-tit{
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;
    .van-icon{
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
    .title{
        font-weight: bold;
        font-size: 22px;
        padding: 10px;
    }
.firsts {
    width: 100%;
    height: 0.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.3rem;
    span {
        display: inline-block;
        font-size: 0.4rem;
        width: 20%;
        text-align: center;
    }
    input {
        display: inline-block;
        width: 20%;
        height: 0.4rem;
        border-radius: 0px;
        font-size: 0.4rem;
        -webkit-appearance: none; /*去除系统默认的样式*/
    }
}
.isSubmit{
    margin-top: 1rem;
    text-align: center;
    .van-button-p{
        margin: 0 10px;
    }
}
</style>