<template>
    <div>
        <Head :title="'任务完成'" :out="'ProcessTask?active=2'" />
        <div class="content">
             <div class="btn-bom">
                <van-button v-if="isObj.is_plant_dispatching.isMust==1" class="content-btn"  type="info" @click="goInfoTi(1)" size="large">
                    需毁形拍照任务
                    <van-tag v-if="isObj.is_plant_dispatching.is_fill_in==1" type="danger" size="medium">完成</van-tag>
                </van-button>
                <van-button v-if="isObj.is_number_of_process_photos.isMust!=0" class="content-btn"  type="info" @click="goInfoTi(2)" size="large">
                    需工序拍照任务
                    <van-tag v-if="isObj.is_number_of_process_photos.is_fill_in==1" type="danger" size="medium">完成</van-tag>
                </van-button>
                <van-button v-if="isObj.is_enter_reusable_parts.isMust==1" class="content-btn"  type="info" @click="goInfoTi(3)" size="large">
                    需录入回用件业务
                    <van-tag v-if="isObj.is_enter_reusable_parts.is_fill_in==1" type="danger" size="medium">完成</van-tag>
                </van-button>
                <van-button v-if="isObj.is_bulk_disassembly.isMust==1" class="content-btn"  type="info" @click="goInfoTi(4)" size="large">
                    需录入大宗业务
                    <van-tag v-if="isObj.is_bulk_disassembly.is_fill_in==1" type="danger" size="medium">完成</van-tag>
                </van-button>
                <van-button v-if="isObj.is_hazardous_waste.isMust==1" class="content-btn"  type="info" @click="goInfoTi(5)" size="large">
                    需录入危废业务
                    <van-tag v-if="isObj.is_hazardous_waste.is_fill_in==1" type="danger" size="medium">完成</van-tag>
                </van-button>
                <van-button  class="content-btn"  type="info" @click="goInfoTi(6)" size="large">
                    需录入生产备注
                    <van-tag v-if="isObj.is_remark.is_fill_in==1" type="danger" size="medium">完成</van-tag>
                </van-button>
            </div>
        </div>
        <div class="vanbut">
        
            <van-button v-if="isObj.status!=9" class="van-button-p"  type="info" @click="onSubmit" size="normal">完成任务</van-button>
        </div>
         <van-popup
            v-model="isAddShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <destroyImg v-if="ids == 1" ref="destroyRef" @closePopup="closePopup"  />
            <processImgs v-if="ids == 2" ref="processRef" @closePopup="closePopup"  />
            <reuse v-if="ids == 3" ref="reuseRef" @closePopup="closePopup"  />
            <Alarge v-if="ids == 4" ref="largeRef" @closePopup="closePopup"  />
            <hazardous v-if="ids == 5" ref="dousRef" @closePopup="closePopup"  />
            <IsRemark v-if="ids == 6" ref="remarkRef" @closePopup="closePopup"  />
    </van-popup>
    </div>
</template>

<script>
import Head from '@/components/Head'
import {Dialog} from 'vant'
import { processRequirements,submit } from '@/api/gong'
import destroyImg from './components/destroyImg.vue'//毁形照片
import processImgs from './components/processImgs.vue'//工序照片
import reuse from './components/reuse'//回用件
import hazardous from './components/hazardous'//危废
import Alarge from './components/Alarge'//大宗
import IsRemark from './components/IsRemark'//大宗
    export default {
        components: {
            Head,destroyImg,processImgs,reuse,hazardous,Alarge,IsRemark
        },
        data() {
            return {
                isObj:{
                    is_bulk_disassembly: {isMust: 0, is_fill_in: 0},
                    is_enter_reusable_parts: {isMust: 0, is_fill_in: 0},
                    is_hazardous_waste: {isMust: 0, is_fill_in: 0},
                    is_number_of_process_photos: {isMust: 0, is_fill_in: 0},
                    is_plant_dispatching: {isMust: 0, is_fill_in: 0},
                    is_remark: {isMust: 0, is_fill_in: 0},
                    status:0,
                },
                isAddShow:false,
                ids:0,
            }
        },
        mounted () {
            this.lodData();
        },
        methods: {
            closePopup(){
                this.isAddShow = false
                 this.lodData();
                
            },
            onSubmit(){
                    Dialog.confirm({
                        title: '提示',
                        message: '确认是否提交？',
                    })
                    .then(async () => {
                        // on confirm
                        try{
                            const {data} = await submit({id:this.$route.query.id}).then(res=>res)
                            this.$toast.success(data.msg)
                            this.$router.push({path:'ProcessTask',query:{active:3}})
                        }
                        catch{}
                       
                    })
                    .catch(() => {
                        // on cancel
                    });
                  
                
                
            },
            async lodData() {
                const {data} = await processRequirements({id:this.$route.query.id}).then(res=>res)
                
                this.isObj = data.data
            },
            goInfoTi(num){
                this.ids = num
                this.isAddShow = true
                this.wcConfig();
                 this.$nextTick(()=>{
                    if(this.ids == 1) {
                        this.$refs.destroyRef.find(this.$route.query.id,1)
                    }else if(this.ids == 2) {
                        this.$refs.processRef.find(this.$route.query.id,3)
                    }else if(this.ids == 3) {
                        this.$refs.reuseRef.kong(this.$route.query.id,4)
                      
                            // this.$refs.reuseRef.find(this.$route.query.id,4)
                        
                    }else if(this.ids == 4){
                        this.$refs.largeRef.kong(this.$route.query.id,2)
                      

                            // this.$refs.largeRef.find(this.$route.query.id,2)
                    
                    }else if(this.ids == 5){
                        this.$refs.dousRef.kong(this.$route.query.id,5)
                   

                            // this.$refs.dousRef.find(this.$route.query.id,5)
                        
                    }else if(this.ids == 6){
                        this.$refs.remarkRef.find(this.$route.query.id,6)

                    }
                    
                 })
            },
        },
    }
</script>

<style lang="scss" scoped>
.content{
    margin-top: 50px;
    .btn-bom{
        padding: 0 10px;
        margin-top: 50px;
        .content-btn{
            margin: 10px 0;
        }
    }
}
    .vanbut{
        margin-top: 3rem;
       text-align: center;
      .van-button-p{
        width: 200px;
      }
    }
</style>