<template>
    <div >
        <div class="warper-tit">
            <van-icon name="close" @click="$emit('closePopup')" />
        </div>
         <p class="title">生产备注</p>
         <div class="warper">

            <van-field
                v-model="remark"
                rows="4"
                autosize
                class="fieldt"
                type="textarea"
                placeholder="请输入生产备注"
                />
         </div>
          <div class="isSubmit">
            <van-button class="van-button-p"  type="info"  @click="Submit">提交</van-button>
        </div>
    </div>
</template>

<script>
import { productionRemarks,recordsFind } from '@/api/gong'
import {Toast} from 'vant'
    export default {
        data() {
            return {
                remark:''
            }
        },
        methods: {
            async Submit(){
                const {data} = await productionRemarks({id:this.$route.query.id,remark:this.remark}).then(res=>res)
                this.$toast.success(data.msg)
                this.$emit('closePopup')
            },
            async find(id,type){
                 const {data} = await recordsFind({id,type}).then(res=>res)
                this.remark = data.data.val
            }
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    padding: 10px;
}
.warper-tit{
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;
    .van-icon{
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
.title{
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
}
.fieldt{
    border: 1px solid #eee;
}
.isSubmit{
margin-top: 3rem;
text-align: center;
.van-button-p{
    margin: 0 10px;
}
}
</style>