<template>
    <div>
         <div class="warper-tit">

            <van-icon name="close" @click="$emit('closePopup')" />
        </div>
         <p class="title">【工序名称】拍照任务</p>
          <div v-if="!isWechat">
            <van-field name="uploader" >
                <template #input>
                    <!-- :before-read="beforeRead" -->
                    <van-uploader  v-model="imageFile" :after-read="uploadershang('restsImg')" :before-read="beforeRead" multiple  :before-delete="deleteimg2"></van-uploader>
                </template>
            </van-field>
        </div>
        <div class="upimg" v-else>
            <!-- <p>【工序名称】拍照任务</p> -->
            <p>
                <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                    <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                        <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                    </van-image>
                </span>
                <span class="uploaderImg" @click="WeChooseImage(9)"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </p>
        </div>
        <div class="mmm">
             <van-button class="van-button-p"  type="info"  @click="tijiao" size="large">提交</van-button>
        </div>
    </div>
</template>

<script>
import {accordingToTask,recordsFind} from '@/api/gong'
import {Toast} from 'vant'
    export default {
        data() {
            return {
                WXimg:[],
                imageFile:[],
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                islable:"",//判断上川的什么类型照片进行识别
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: {
            async find(id,type){
                const {data} = await recordsFind({id,type}).then(res=>res)
                console.log(data)
                if(data.data.val.length !=0){

                    this.WXimg = data.data.val
                }else {
                    this.WXimg = []
                }
                let arr =[]
                this.WXimg.forEach(i=>{
                    let obj = {
                        url:i
                    }
                    arr.push(obj)
                })
                this.imageFile =arr
            },
            async tijiao(){
                try{
                    const {data} = await accordingToTask({id:this.$route.query.id,image:this.WXimg}).then(res=>res)
              
                    this.$toast.success(data.msg)
                    this.$emit('closePopup')
                }
                catch{}
            },
            deleteimg2(file,datail){
                this.imageFile.forEach((item,index)=>{
                    if(index == datail.index){   // 判断删除的下标是否正确
                            this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
                            this.WXimg.splice(index,1) // 使用遍历找到的index去数组里面删除
                            console.log(this.imageFile)
                        }
                })
                // this.imageArr.forEach((item,index)=>{
                    
                //     if(index == datail.index){   // 判断删除的下标是否正确
                //             this.imageArr.splice(index,1) // 使用遍历找到的index去数组里面删除
                //         }
                // })
            },
            beforeRead(file){
                if(file instanceof Array){
                    for(let i=0;i<file.length;i++){
                        console.log(file[i].type)
                        if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                            Toast('请上传 jpg/png 格式图片');
                            return false;
                        }
                        return true;
                    }
                }else{
                    console.log(file.type)
                    if((file.type !== 'image/jpeg' ) && (file.type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
           },
            // 上传照片
           uploadershang(i){
                return file =>{
                    file.status = "uploading";
                    file.message = "上传中...";
                    if(file instanceof Array){
                        file.forEach(item=>{
                            this.upImages(i,item)
                            console.log(i)
                        })
                    }else{
                        this.upImages(i,file)
                    }
                }
           },
           upImages(i,file){
            file.status = "uploading";
            file.message = "上传中...";
            let params = new FormData(); //创建form对象
            params.append("file", file.file);
            this.$http.post("/index.php/index/upload/index", params)
            .then((res) => {
                // toast.clear()//清除加载效果
            if (res.data.code == 0) {
                    file.status = "done";
             
                    this.WXimg.push(res.data.data)
                } else if(res.data.code == -1){
                    deleteCookie('token')
                    this.$toast.fail(res.data.msg)
                    this.$router.push('/')
                }else{
                    file.status = "failed";
                   file.message = "上传失败";
                    this.$toast.fail(res.data.msg)
                }
                }).catch(()=>{
                   
                    if(i=='restsImg'){
                        this.imageFile = []
                    }
                    // toast.clear()//清除加载效果
                    file.status = "failed";
                    file.message = "上传失败";
                    this.$toast.fail('网络开小差了，请稍后重试！')
                })
            }
        
        },
    }
</script>

<style lang="scss" scoped>
.warper-tit{
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;
    .van-icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
.mmm{
    margin-top: 50px;
}
 .title{
        font-weight: bold;
        font-size: 22px;
        padding: 10px;
    }
.upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>